<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <ion-list>
			<ion-item v-if="canPost" :router-link="route.path + '/new'">Добавить запись</ion-item>
            <ion-item class="ion-no-padding" v-for="item in posts" v-bind:key='item.id'>
                <div class="post">
                    <div class="post__header">
                        <span class="post__title">{{item.title}}</span><span class="post__date">{{item.created_at}}</span>
                    </div>
                    <div class="post__body">
                        <div class="post__author" @click="() => $router.push(getUserPath(item.user))">
                            <img v-bind:src="getUserAvatar(item.user)">
                            <div class="post__username">{{item.user.nickname}}</div>
                            <div class="post__sign">{{item.user.sign}}</div>
                        </div>
                        <div class="post__text" v-html="getRichText(item.text, true)" @click="() => $router.push(getPostPath(blog, item))" router-link="getPostPath(blog, item)">
                        </div>
                    </div>
					<div class="like-wrapper" :class="{liked: item.liked.length}" @click="doLike(item, 'post')">
						<fa icon="heart" class="fas-icon"></fa>
					</div>
					<div class="post__commcount">Комментариев: {{item.comments_count}}</div>
                </div>
            </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.post {
	padding-bottom: 20px;
}
.post__title {
    font-weight: bold;
	font-size: 120%;
}
.post__date {
	color: #808080;
    font-size: 80%;
    margin-left: 1rem;
}
.post__body {
    display: flex;
}
.post__text {
    flex-grow: 1;
	word-break: break-word;
}
.post__author {
    text-align: center;
	margin-right: 1%;
	max-width: 120px;
}
.post__sign {
    font-size: 80%;
    color: #808080;
}
.post__commcount {
	text-align: right;
}
.like-wrapper {
	margin-top: 1em;
}
.like-wrapper.liked {
	color: red;
}
.fas-icon, .editools span {
	height: 1.5rem;
	font-size: 1.5rem;
}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getPostPath: function(blog: any, item: any){
			return "/blog/" + blog.name + "/post/" + item.blogpostid;
		},
		getUserAvatar: function(user: any){
			return "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext;
		}
	},
  setup() {
    const route = useRoute();
    const folder = ref(route.params.id || 'Inbox');
    const matchedFolder = computed(() => route.params.id);
	const posts = ref({});
	const blog = ref<any>({});
	const blogslug = computed(() => route.params.blogslug);
	const { token, getRichText, doLike } = useDataService();
	const canPost = ref<boolean>();
	
	watch (blogslug, () => {
		if(blogslug.value){
			axios.get('https://beon.fun/api/v1/blog/'+blogslug.value,{
			headers: {
				Authorization: 'Bearer ' + token.value,
			}})
			.then(response => (
			posts.value = response.data.posts,
			blog.value = response.data.blog,
			folder.value = response.data.blog.title,
			canPost.value = response.data.blog.can_post
			));
		}
	})
    watch(matchedFolder, () => {
		folder.value = (blog.value ? blog.value.title : matchedFolder.value as string);
    })
	
	axios.get('https://beon.fun/api/v1/blog/'+blogslug.value,{
	headers: {
		Authorization: 'Bearer ' + token.value,
	}})
    .then(response => (
	posts.value = response.data.posts,
	blog.value = response.data.blog,
	folder.value = response.data.blog.title,
	canPost.value = response.data.blog.can_post
	));
    return { folder, posts,blog, canPost,route, getRichText, doLike }
  }
}
</script>