
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getPostPath: function(blog: any, item: any){
			return "/blog/" + blog.name + "/post/" + item.blogpostid;
		},
		getUserAvatar: function(user: any){
			return "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext;
		}
	},
  setup() {
    const route = useRoute();
    const folder = ref(route.params.id || 'Inbox');
    const matchedFolder = computed(() => route.params.id);
	const posts = ref({});
	const blog = ref<any>({});
	const blogslug = computed(() => route.params.blogslug);
	const { token, getRichText, doLike } = useDataService();
	const canPost = ref<boolean>();
	
	watch (blogslug, () => {
		if(blogslug.value){
			axios.get('https://beon.fun/api/v1/blog/'+blogslug.value,{
			headers: {
				Authorization: 'Bearer ' + token.value,
			}})
			.then(response => (
			posts.value = response.data.posts,
			blog.value = response.data.blog,
			folder.value = response.data.blog.title,
			canPost.value = response.data.blog.can_post
			));
		}
	})
    watch(matchedFolder, () => {
		folder.value = (blog.value ? blog.value.title : matchedFolder.value as string);
    })
	
	axios.get('https://beon.fun/api/v1/blog/'+blogslug.value,{
	headers: {
		Authorization: 'Bearer ' + token.value,
	}})
    .then(response => (
	posts.value = response.data.posts,
	blog.value = response.data.blog,
	folder.value = response.data.blog.title,
	canPost.value = response.data.blog.can_post
	));
    return { folder, posts,blog, canPost,route, getRichText, doLike }
  }
}
